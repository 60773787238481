<template>
  <div v-if="question">
    <sqr-input-number
      :label="question.name"
      :placeholder="question.placeholder"
      :required="question.required"
      :value="answer"
      @change="$emit('change', $event)"
      :disabled="disabled"
      :v="v"
    />
  </div>
</template>

<script>
import SqrInputNumber from '@/sqrd-ui/SqrInputNumber';
export default {
  name: 'NumberView',
  components: { SqrInputNumber },
  props: {
    question: { type: Object },
    answer: { type: Number },
    disabled: { type: Boolean },
    v: { type: Object },
  },
};
</script>
